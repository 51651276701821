import { Box, chakra, Collapse, Container, Flex, Icon, VStack, Tooltip } from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { useDevice } from "@/contexts/DeviceContext";
import { motion } from "framer-motion";
import GetStartedButtonLoader from "./navbar/GetStartedButtonLoader";
import { DiscountBanner } from "./navbar/Banner";
import OptimizedImage from "./shared/OptimizedImage";
import { parseCookies } from "nookies";
import { withUserData } from "@/contexts/UserDataContext";
import { useGetEssaysPath } from "@/hooks/useGetEssaysPath";

const useMenu = () => {
  const essaysPath = useGetEssaysPath();

  return [
    { title: "Read Profiles", path: "/profiles", prefetch: false },
    {
      title: "Revise Essays",
      path: `${essaysPath}`,
      prefetch: false,
    },
    {
      title: "Schools",
      children: [
        {
          title: "Stanford",
          path: "/example-essays/stanford",
          regularNav: true,
        },
        { title: "Harvard", path: "/example-essays/harvard", regularNav: true },
        { title: "MIT", path: "/example-essays/mit", regularNav: true },
        {
          title: "Columbia",
          path: "/example-essays/columbia",
          regularNav: true,
        },
        {
          title: "Princeton",
          path: "/example-essays/princeton",
          regularNav: true,
        },
        { title: "Duke", path: "/example-essays/duke", regularNav: true },
        { title: "UPenn", path: "/example-essays/upenn", regularNav: true },
        { title: "Yale", path: "/example-essays/yale", regularNav: true },
        {
          title: "Dartmouth",
          path: "/example-essays/dartmouth",
          regularNav: true,
        },
        { title: "Cornell", path: "/example-essays/cornell", regularNav: true },
        {
          title: "UChicago",
          path: "/example-essays/uchicago",
          regularNav: true,
        },
        { title: "Brown", path: "/example-essays/brown", regularNav: true },
      ],
    },
    {
      title: "ChanceMe",
      path: "/tools/acceptance-rate-calculator",
      prefetch: false,
      toolTip: "Acceptance Rate Calculator",
    },
    {
      title: "Pricing",
      children: [
        { title: "For Students", 
         path: "/plans", 
         regularNav: true, 
         prefetch: false, 
         isPaid: false },
        {
          title: "For Parents",
          path: "https://admityogiparents.lpages.co/parents/",
          regularNav: true,
        },
        {
          title: "For Schools",
          path: "https://admityogi-b2b.web.app/",
          regularNav: true,
        },
      ],
    },
    // {
    //  title: "Refer & Earn",
    //  path: "https://form.typeform.com/to/koG60xMb",
    //  prefetch: false,
    // },
    // {
    //   title: "Free Tools & Resources",
    //   children: [
    //     { title: "Find Internships", path: "/summer-programs-and-internships", prefetch: false },
    //     { title: "Find Scholarships", path: "/scholarships", prefetch: false },
    //     { title: "Blog", path: "/blog", prefetch: false },
    //   ],
    // },
  ];
};

export const NavItem = ({
  title,
  path,
  children,
  icon,
  regularNav,
  prefetch,
  isPaid,
  toolTip,
  isChildren,
}) => {
  const router = useRouter();

  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(
      path && path.includes("https://")
        ? window.location.href.startsWith(path)
        : router.asPath.startsWith(path)
    );
  }, []);

  if ((router.query.utm_source === "reddit" || parseCookies().utm_source === "reddit") && isPaid) {
    return null;
  }

  if (children) {
    // Dropdown menu
    return (
      <chakra.div
        px="8px"
        cursor="pointer"
        position="relative"
        zIndex={20}
        onClick={() => setIsHovered((i) => !i)}
      >
        <Flex justifyContent="center" alignItems="center">
          {title}
          <ChevronDownIcon />
        </Flex>

        <Box
          display={{ base: "none", md: isHovered ? "block" : "none" }}
          position="absolute"
          w="150px"
          top="100%"
          left="50%"
          transform="translateX(-50%) !important"
          as={motion.div}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          bg="white"
          border="1px solid black"
          mt={1}
          p={4}
          zIndex={2}
          borderRadius="md"
          color="black"
          textShadow="md"
        >
          <VStack
            gap={1.5}
            alignItems="left"
            textAlign="left"
            lineHeight={1.2}
            fontFamily="heading"
          >
            {children.map((child, index) => (
              <NavItem key={index} isChildren {...child} />
            ))}
          </VStack>
        </Box>

        <Flex
          flexDir="column"
          alignItems="center"
          pl={4}
          display={{ base: isHovered ? "flex" : "none", md: "none" }}
        >
          {children.map((child, index) => (
            <NavItem key={index} {...child} />
          ))}
        </Flex>
      </chakra.div>
    );
  }

  // Single link
  let content = (
    <chakra.a
      href={path}
      zIndex={1}
      cursor="pointer"
      textDecor={isActive ? "underline" : "none"}
      padding={isChildren ? "0" : "0 8px"}
      fontSize={{ md: "sm", lg: "md" }}
      _hover={{ textDecor: "underline" }}
    >
      {icon && <Icon as={icon} w=".75em" mr={1.5} transform="translateY(.125em)" />}
      {title}
    </chakra.a>
  );

  if (toolTip) {
    content = (
      <Tooltip
        label={toolTip}
        bg="#ffffff"
        color="#000000"
        border="1px solid #000"
        borderRadius="4px"
        padding="12px 8px"
      >
        {content}
      </Tooltip>
    );
  }

  if (regularNav) {
    return content;
  }

  return (
    <Link href={path} prefetch={prefetch} legacyBehavior>
      {content}
    </Link>
  );
};

export const Logo = ({ inverted, customLogo }) => {
  if (customLogo) {
    return (
      <Link href="/" legacyBehavior>
        <chakra.a href="/" mr="auto" cursor="pointer" display="flex" alignItems="center">
          {customLogo}
        </chakra.a>
      </Link>
    );
  }

  return (
    <Link href="/" legacyBehavior>
      <chakra.a href="/" mr="auto" cursor="pointer" display="flex" alignItems="center">
        <OptimizedImage
          priority
          draggable={false}
          src={`/images/logo-${inverted ? "white" : "black"}.svg`}
          alt="admityogi"
          width={150}
          height={30}
        />
      </chakra.a>
    </Link>
  );
};

const MobileNav = ({ inverted: origInverted, customLogo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inverted, setInverted] = useState(origInverted);

  useEffect(() => {
    setInverted(isOpen ? false : origInverted);
  }, [isOpen]);

  const menu = useMenu();

  return (
    <Box
      as={motion.div}
      display={{ base: "block", md: "none" }}
      borderRadius="3xl"
      py={4}
      animate={{ backgroundColor: isOpen ? "#ffffffff" : "#ffffff00" }}
      mt={2}
    >
      <Flex
        as={motion.div}
        key={0}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        align="center"
      >
        <Logo inverted={inverted} customLogo={customLogo} />

        <Box
          zIndex={1}
          as={motion.div}
          onClick={() => setIsOpen((i) => !i)}
          animate={{
            backgroundColor: isOpen ? "#eeeeeeff" : inverted ? "#ffffff44" : "#222222ff",
          }}
          py={1}
          px={4}
          mr={2}
          borderRadius="3xl"
        >
          {isOpen ? (
            <CloseIcon boxSize={4} color="black" />
          ) : (
            <HamburgerIcon color="white" boxSize={6} />
          )}
        </Box>
      </Flex>

      <Collapse in={isOpen}>
        <Flex
          color="black"
          flexDir="column"
          alignItems="center"
          gap={3}
          p={4}
          fontSize="2xl"
          fontWeight="normal"
          pb={0}
        >
          {menu.map((item, index) => (
            <NavItem key={index} inverted={inverted} {...item} />
          ))}

          <GetStartedButtonLoader isOpen={isOpen} fullWidth />
        </Flex>
      </Collapse>
    </Box>
  );
};

const DesktopNav = ({ inverted, customLogo }) => {
  const menu = useMenu();

  const { isMobile } = useDevice();

  if (isMobile) {
    return null;
  }

  return (
    <Flex
      display={{ base: "none", md: "flex" }}
      justifyContent="space-between"
      gap={6}
      py={8}
      alignItems="center"
    >
      <Logo inverted={inverted} customLogo={customLogo} />

      {menu.map((item, index) => (
        <NavItem key={index} inverted={inverted} {...item} />
      ))}

      <Box ml={6}>
        <GetStartedButtonLoader />
      </Box>
    </Flex>
  );
};

function Navbar({ inverted, bg, containerProps = {}, customLogo }) {
  const color = inverted ? "white" : undefined;
  const fontWeight = inverted ? "bold" : "normal";

  return (
    <>
      <chakra.nav bg={bg}>
        <DiscountBanner />

        <Container color={color} fontWeight={fontWeight} maxW="container.xl" {...containerProps}>
          <DesktopNav inverted={inverted} customLogo={customLogo} />

          <MobileNav inverted={inverted} customLogo={customLogo} />
        </Container>
      </chakra.nav>
    </>
  );
}

export default withUserData(Navbar);
